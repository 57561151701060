import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

import logo from "../images/infinity-logo.gif"
import NavLink from './NavLink' // replaces NavLink from react-bootstrap


const Header = ({ siteTitle }) => (
  <div className="masthead">
  <Navbar
    collapseOnSelect
    inverse
    staticTop
  >
    <Navbar.Header>
      <Navbar.Brand>
        <Link to="/">
          <img src={logo} alt="Infinity Structures Logo" className="header-logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle />
    </Navbar.Header>
    <Navbar.Collapse>
      <Nav>
        <NavLink to='/'>
          Home
        </NavLink>
        <NavDropdown eventKey={2} title="System Components" id="nav-system-components-dropdown">
          <NavDropdown
            className='nest-inner-1'
            eventKey={2.1}
            id="nav-system-components-epicore-msr-dropdown"
            title="EPICORE MSR Floor System"
          >
            <NavLink to='/system-components/epicore-msr-floor-system/'>
              EPICORE MSR Floor System
            </NavLink>
            <NavLink to='/system-components/epicore-msr-floor-system/ul-rating/'>
              U.L. Rating
            </NavLink>
            <NavLink to='/system-components/epicore-msr-floor-system/stc-rating/'>
              STC Rating
            </NavLink>
            <NavDropdown
              eventKey={2.2}
              title="Ceiling Treatment"
              id="nav-system-components-epicore-msr-ceiling-treatments-dropdown"
            >
              <NavLink to='/system-components/epicore-msr-floor-system/ceiling-treatment/'>
                Ceiling Treatment
              </NavLink>
              <NavLink to='/system-components/epicore-msr-floor-system/ceiling-treatment/painting/'>
                Painting
              </NavLink>
              <NavLink to='/system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/'>
                Exposed Galvanized
              </NavLink>
              <NavLink to='/system-components/epicore-msr-floor-system/ceiling-treatment/hat-channels-and-drywall/'>
                Hat Channels and Drywall
              </NavLink>
            </NavDropdown>
          </NavDropdown>
          <NavLink to='/system-components/metal-stud-wall-panels/'>
            Metal Stud Wall Panels
          </NavLink>
          <NavLink to='/system-components/infinity-shear-panels/'>
            Infinity Shear Panels
          </NavLink>
          <NavLink to='/system-components/panelcad/'>
            PanelCAD
          </NavLink>
        </NavDropdown>
        <NavDropdown eventKey={3} title="System Overview" id="nav-system-overview-dropdown">
          <NavLink to='/system-overview/metal-stud-walls/'>
            Metal Stud Walls
          </NavLink>
          <NavLink to='/system-overview/installing-wall-panels/'>
            Installing Wall Panels
          </NavLink>
          <NavLink to='/system-overview/lateral-loads/'>
            Lateral Loads
          </NavLink>
          <NavLink to='/system-overview/epicore-msr-floor'>
            EPICORE MSR Composite Floor
          </NavLink>
          <NavLink to='/system-overview/mep-in-slab/'>
            M/E/P In Slab
          </NavLink>
          <NavLink to='/system-overview/shoring-reshoring/'>
            Shoring Reshoring
          </NavLink>
          <NavLink to='/system-overview/pouring-concrete/'>
            Pouring Concrete
          </NavLink>
          <NavLink to='/system-overview/slab-beams/'>
            Slab Beams
          </NavLink>
          <NavLink to='/system-overview/rebar-headers/'>
            Rebar Headers
          </NavLink>
          <NavLink to='/system-overview/steel-beams/'>
            Steel Beams
          </NavLink>
          <NavLink to='/system-overview/cantilevers/'>
            Cantilevers
          </NavLink>
          <NavLink to='/system-overview/ceiling-treatments/'>
            Ceiling Treatments
          </NavLink>
          <NavLink to='/system-overview/demising-walls/'>
            Demising Walls
          </NavLink>
          <NavLink to='/system-overview/roof-systems/'>
            Roof Systems
          </NavLink>
        </NavDropdown>
        <NavDropdown eventKey={4} title="Gallery" id="nav-gallery-dropdown">
            <NavLink to='/gallery/student-housing/'>
              Student Housing
            </NavLink>
            <NavLink to='/gallery/hotels/'>
              Hotels
            </NavLink>
            <NavLink to='/gallery/apartments-and-condos/'>
              Apartments, Condos
            </NavLink>
            <NavLink to='/gallery/senior-housing/'>
              Senior Housing
            </NavLink>
        </NavDropdown>
        <NavLink to='/request-info/'>
          Request Info
        </NavLink>
        <NavLink to='/contact/'>
          Contact
        </NavLink>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
