import React from 'react';
import { graphql } from "gatsby"
import {
  ButtonBack, ButtonNext, ButtonPlay,
  CarouselProvider, Dot, Slider, Slide
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Img from 'gatsby-image';

import styles from './carousel.module.css';


export default ({content, carWidth, carHeight, autoPlay}) => (
  <CarouselProvider
    interval={7000}
    isPlaying={autoPlay == null || autoPlay}
    naturalSlideHeight={carHeight}
    naturalSlideWidth={carWidth}
    totalSlides={content.length}
  >
    <div className='grid grid--gutterless'>
      <div className='grid__column grid__column--12'>
        <Slider>
          {content.map((row, ind) => {
            return <Slide index={ind} key={ind} style={{}}>
              <Img
                alt={row.caption}
                fluid={row.img.childImageSharp.fluid}
              />
            </Slide>
          })}
        </Slider>
      </div>

      <div className='grid__column grid__column--10 grid__column--6--sm'>
        {content.map((row, ind) => {
          return <Dot slide={ind} key={ind} className={styles.carousel__caption}>{row.caption}</Dot>
        })}
      </div>

      <div className='grid__column grid__column--2 grid__column--6--sm type-right'>
        <ButtonBack className={styles.carousel__arrows} style={{outline: 'none'}}>
          <i className='icon-left-open' />
        </ButtonBack>
        <ButtonPlay
          childrenPaused={<i className='icon-play' />}
          childrenPlaying={<i className='icon-pause' />}
          className={styles.carousel__pause}
          style={{outline: 'none'}}
        />
        <ButtonNext className={styles.carousel__arrows} style={{outline: 'none'}}>
          <i className='icon-right-open' />
        </ButtonNext>
      </div>
    </div>
  </CarouselProvider>
)

export const query = graphql`
#   Also used for System Components page galleries
    fragment GalleryCarouselImageFragment on File {
        childImageSharp {
            fluid(maxHeight: 476, maxWidth: 846, quality: 85, toFormat: JPG) {
                ...GatsbyImageSharpFluid
            }
        }
    }


    fragment HomeCarouselImageFragment on File {
        childImageSharp {
            fluid(maxHeight: 540, maxWidth: 960, quality: 85, toFormat: JPG) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    fragment GalleryCarouselTechnical1_77ImageFragment on File {
        childImageSharp {
            fluid(maxHeight: 476, maxWidth: 846, toFormat: PNG) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    fragment SystemOverviewCarouselImageFragment on File {
        childImageSharp {
            fluid(maxHeight: 316, maxWidth: 474, quality: 85, toFormat: JPG) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    fragment SystemOverviewCarouselTechnical1_5ImageFragment on File {
        childImageSharp {
            fluid(maxHeight: 316, maxWidth: 474, toFormat: PNG) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`
