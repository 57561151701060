import { Link } from 'gatsby'
import React from 'react'

const Footer = () => (
  <footer className='mastfoot'>
    <div className='container--fluid bg-color-primary type-center padding-xxs padding-y margin-xxs margin-y'>
      <Link to="/contact/" style={{textDecoration: 'none'}}>
        <h3 className='title title--xlg color-lightest'>
          Click Here to Contact <nobr>Infinity Structures</nobr> (678)&nbsp;<nobr>513-4080</nobr>
        </h3>
      </Link>
    </div>
    <div className='container'>
      <div className='grid' style={{fontSize: '0.8rem'}}>
        <div className='grid__column grid__column--12 grid__column--5--md'>
          <h4 className='title title--sm color-primary'>
            About Infinity Structures
          </h4>
          <p>Since 1986, The Infinity Structural System has been utilized in
          hundreds of buildings as a faster and less expensive alternative
          to traditional concrete, pre-cast or structural steel frames.</p>
        </div>
        <div className='grid__column grid__column--12 grid__column--7--md'>
          <div className='grid'>
            <div className='grid__column grid__column--12 grid__column--6--sm'>
              <h4 className='title title--sm color-primary'>
                System Components
              </h4>
              <ul className='list list--unstyled'>
                <Link to="/system-components/epicore-msr-floor-system/">
                  <li className='list__item'>EPICORE MSR Floor System</li>
                </Link>
                <Link to="/system-components/metal-stud-wall-panels/">
                  <li className='list__item'>Metal Stud Wall Panels</li>
                </Link>
                <Link to="/system-components/infinity-shear-panels/">
                  <li className='list__item'>Infinity Shear Panels</li>
                </Link>
                <Link to="/system-components/panelcad/">
                  <li className='list__item'>PanelCAD</li>
                </Link>
              </ul>
            </div>
            <div className='grid__column grid__column--12 grid__column--6--sm'>
              <h4 className='title title--sm color-primary'>
                Applications
              </h4>
              <ul className='list list--unstyled'>
                <Link to="/gallery/student-housing/"><li className='list__item'>Student Housing</li></Link>
                <Link to="/gallery/hotels/"><li className='list__item'>Hotels</li></Link>
                <Link to="/gallery/apartments-and-condos/"><li className='list__item'>Apartments, Condos</li></Link>
                <Link to="/gallery/senior-housing/"><li className='list__item'>Senior Housing</li></Link>
              </ul>
            </div>
          </div>
        </div>

        <div className='grid__column grid__column--12' style={{marginTop: '3rem'}}>
          <p className='type-center footer--copyright'>Copyright © 2020 Infinity Structures, Inc – All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
