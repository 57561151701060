import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../../components/carousel'
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Exposed Epicore MSR as Loft Ceiling' },
    { img: data.i2, caption: 'Exposed Epicore MSR as Loft Ceiling' },
    { img: data.i3, caption: 'Exposed Epicore MSR as Loft Ceiling' },
    { img: data.i4, caption: 'Exposed 20 ga Epicore MSR' },
    { img: data.i5, caption: 'Epicore MSR 22 ga - Exposed Loft' },
    { img: data.i6, caption: 'Epicore MSR 22 ga - Exposed Loft' },
    { img: data.i7, caption: 'Epicore MSR 22 ga - Exposed Loft' },
    { img: data.i8, caption: 'Epicore MSR 22 ga - Exposed Loft' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: Ceiling Treatment - Exposed Galvanized Deck" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>Ceiling Treatment - Exposed Galvanized Deck</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>One option with the Epicore MSR Floor System is to simply leave
          the bottom of the EPICORE MSR deck as an exposed galvanized finish.
          Thoughtfully combined with exposed ductwork and sprinkler piping, the
          exposed EPICORE MSR deck can achieve a high-tech industrial loft look while
          also providing the cost savings of eliminating the drywall ceilings.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/01-CEILI260_(34288).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/02-CEILI270_(34278).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/03-CEILI280_(34279).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/04-AtCol020_(34289).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/05-CEILI450_(40585).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/06-CEILI490_(40589).png" }) {
            ...GalleryCarouselImageFragment },
        i7: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/07-CEILI460_(40586).png" }) {
            ...GalleryCarouselImageFragment },
        i8: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/exposed-galvanized/gallery/08-CEILI480_(40588).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
