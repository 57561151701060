import React from 'react';
import { Link } from 'gatsby';

// react-bootstrap passes activeHref, activeKey which we don't want to use
// However, we do want to use Link from gatsby because it will properly
// interact with react-router and preload scripts for linked pages
export default ({active, activeHref, activeKey, children, location, to}) => (
  <li role="presentation">
    <Link to={to} activeClassName='active'>{location}{children}</Link>
  </li>
);
